<template>
  <div class="mb-50">
    <div v-loading="loading">
      <el-form
        :model="form"
        ref="form"
        label-width="92px"
        label-position="right"
        :inline="false"
        class="demo-form-inline"
      >
        <!-- ----------- -->
        <div class="border-b" style="margin-bottom: 30px">
          <el-form-item label="选择地区：" prop="activeDateIdx">
            <div style="display: flex; flex-wrap: wrap;">
                  <span
                    class="type-item"
                    @click="onTabs('', 'addressCode')"
                    :class="{ is_active: addressCode === '' }"
                  >全部</span
                  >
              <span
                class="type-item"
                :class="{ is_active: addressCode == item.adCode }"
                @click="onTabs(item, 'addressCode')"
                v-for="(item, index) in addressData"
                :key="index"
              >
                    {{ item.name }}</span
              >
            </div>
            <div class="flex city_list">
              <div :class="cityCode === item.adCode ? 'city_list_item city_list_item_on' : 'city_list_item'" v-for="(item, index) in cityList" :key="item.adCode" @click="onTabs(item.adCode, 'cityCode')">{{item.name}}</div>
            </div>
          </el-form-item>
          <el-row>
            <el-col :span="24">
              <el-form-item label="需求类型：" prop="1">
                <div style="display: flex">
                  <span
                    class="type-item"
                    :class="{
                      is_active: activeDateIdx === item.name,
                    }"
                    @click="onTabs(item.name, 'activeDateIdx')"
                    v-for="(item, index) in dateList"
                    :key="index"
                  >
                    {{ item.name }}
                  </span>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="关键字：" prop="fullName">
                <div style="display: flex">
                  <el-input
                    style="margin-right: 30px"
                    v-model="form.fullName"
                    placeholder="输入关键字搜索"
                  ></el-input>
                  <el-button
                    style="margin-right: 20px"
                    type="primary"
                    @click="toSearch"
                    >搜索</el-button
                  >
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <!-- 列表 -->
        <div>
          <el-row>
            <el-table
              :data="tableData"
              :show-header="false"
              :expand-row-keys="expandedRows"
              row-key="id"
              border
              :fit="true"
            >
              <el-table-column style="height: 218px">
                <el-row slot-scope="{ row }" type="flex" align="middle">
                  <el-col :span="24">
                    <el-row align="top">
                      <el-col :span="24" style="padding: 0px 40px 5px 0px">
                        <div class="a-flex flex-between">
                          <div style="margin-left: 20px">
                            <div class="list-t mr-40" v-html="row.fullName">
                            </div>
                            <div class="list-t2">{{ row.address }}</div>
                          </div>
                          <div class="btn-wh" @click="getPageOpen(row.unicode)" v-if="row.unicode">
                            查看详情
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-table-column>
            </el-table>
          </el-row>
          <!-- 分页 -->
          <div class="flex project_page">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-size="size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { highlightStringInArray } from '@/utils/public'
import { getEnterpriseHighPage, urlCommonColumnList, urlAdvertisingAdLaunchAppTitle } from "@/api/engineering";
export default {
  data() {
    return {
      addressCode: '',
      cityList: [],
      cityCode: '',
      expandedRows: [],
      dateList: [
        { name: "不限" },
      ],
      activeDateIdx: "不限",
      tableData: [],
      form: {
        fullName: ''
      },
      current: 1,
      size: 10,
      total: 0,
      loading: false
    };
  },
  computed: {
    ...mapState({
      addressData: state => state.address.addressData
    })
  },
  watch: {
    addressData: {
      handler(e) {
        return e
      },
      immediate:  true,
      deep: true
    }
  },
  mounted() {
    this.getEnterpriseHighPage();
    this.getCommonColumnList()
    // this.getAdvertisingAdLaunchAppTitle()
  },
  methods: {
    onTabs(item, type) {
      switch (type) {
        case 'addressCode':
          this.addressCode = item.adCode || '';
          this.cityList = item.child
          if (item.adCode) this.cityCode = item.child[0].adCode
          break;
        case 'cityCode':
          this.cityCode = item;
          break;
        case 'activeDateIdx':
          this.activeDateIdx = item
          break;
      }
      this.getEnterpriseHighPage();
    },
    async getAdvertisingAdLaunchAppTitle() {
      const param = {}
      const res = await urlAdvertisingAdLaunchAppTitle(param)
      console.log('res', res)
    },
    async getCommonColumnList() {
      const param = {
        appid: 'wx7ba99686668dd157'
      }
      const res = await urlCommonColumnList(param)
      let selectMenu = res.data.find(item => item.id == 7)
      let tagsList = []
      if (selectMenu?.enterpriseTags) {
        tagsList = selectMenu.enterpriseTags.split(",").map(item => {
          return {
            name: item
          }
        })
      }
      this.dateList = [...this.dateList, ...tagsList]
    },
    async getEnterpriseHighPage() {
      this.loading = true
      const param = {
        size: this.size,
        current: this.current,
        adCodeList: [this.cityCode],
        name: this.form.fullName,
        tagsList: this.activeDateIdx === '不限' ?  ["工程咨询", "造价咨询", "设计资质", "施工监理", "勘察资质", "质量检测"] : [this.activeDateIdx]
      };
      // 获取API
      const res = await getEnterpriseHighPage(param);
      const { records, size, total } = res.data;
      this.size = size;
      this.total = total;
      this.tableData = highlightStringInArray({data: records, keys: 'fullName'}, this.form.fullName);
      this.loading = false
    },
    toSearch() {
      this.getEnterpriseHighPage();
    },
    // 详情
    getPageOpen(id) {
      this.$router.push({
        path: "/creative-company/detail",
        query: { unicode: sessionStorage.getItem("creditCode") },
      });
    },
    handleSizeChange(e) {
      this.size = e;
      this.getEnterpriseHighPage();
    },
    handleCurrentChange(e) {
      this.current = e;
      this.getEnterpriseHighPage();
    },
  },
};
</script>

<style scoped>
  .city_list {
    flex-wrap: wrap;
    background-color: #F8F8F8;
  }
  .city_list_item {
    padding: 0 10px;
    font-size: 16px;
    color: #4E4E4E;
    cursor: pointer;
  }
  .city_list_item_on {
    color: #007EF2;
  }
.is_active {
  color: #007ef2;
  background-color: #edf4ff;
  border-radius: 5px;
}
.a-flex {
  display: flex;
}
/* 左右 */
.flex-between {
  justify-content: space-between;
  align-items: center;
}
.afw-b {
  font-weight: bolder;
}
.list-z {
  text-align: left;
  font-weight: 500;
  color: #4e4e4e;
  line-height: 40px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.border-b {
  width: 100%;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #edf4ff;
  text-align: left;
  padding: 20px 0px 0px 15px;
}
.mb-50 {
  margin-bottom: 50px;
}
.list-t {
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #4e4e4e;
  line-height: 36px;
}
.list-t2 {
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #4e4e4e;
  line-height: 35px;
}
.btn-wh {
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #1b5c98;
  cursor: pointer;
}
.flex {
  display: flex;
}
.project_page {
  justify-content: flex-end;
  margin-top: 30px;
}
.type-item {
  white-space: nowrap;
  margin-right: 20px;
  padding: 0 10px;
  cursor: pointer;
}
::v-deep .el-table {
  width: 99.99%;
}
</style>
